<template>
  <th-page-wrapper v-loading="loading">
    <services-empty
      v-if="!loading && isEmpty"
      :template-href="templateHref"
      @refresh="refresh"
    />

    <th-datatable
      v-show="!loading && !isEmpty"
      ref="table"
      do-route
      do-route-filters
      show-search-filter
      no-meta-check
      sortable
      resource="products"
      :resource-query="{
        query: { is_reservations_service: true, deleted: false }
      }"
      route-base="/reservations/services"
      :transform-fetched-data="transformFetchedData"
      :search-filters="filtersList"
      :show-operations="false"
      :headers="headers"
      :buttons="computedButtons"
      :permissions="permissions"
      @loading-error="handleLoadingError"
    >
      <template #dropdown-items-start>
        <template-file-button :href="templateHref" />
        <services-importer :resources="resources" @refresh="refresh" />
      </template>
    </th-datatable>
  </th-page-wrapper>
</template>

<script>
import ServicesEmpty from './empty.vue'
import ServicesTableRowLinkedProduct from './components/services-table-row-linked-product.vue'
import ServicesImporter from './components/services-importer.vue'
import TemplateFileButton from '@components/importer/template-file-button'
import { waitForData } from '@/utils/general'

export default {
  name: 'ServicesAll',
  components: {
    ServicesEmpty,
    ServicesImporter,
    TemplateFileButton
  },
  data() {
    return {
      templateHref:
        'https://storage.googleapis.com/tillhub-dashboard/templates/Leistungen.xlsx',
      isEmpty: true,
      loading: false,
      resources: {},
      permissions: {
        create: {
          scopes: ['reservations:services:create']
        },
        update: {
          scopes: ['reservations:services:update']
        },
        delete: {
          scopes: ['reservations:services:delete']
        }
      },
      buttons: [
        {
          type: 'create',
          scopes: ['reservations:services:create']
        }
      ]
    }
  },
  computed: {
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    },
    headers() {
      return [
        {
          field: 'name',
          label: this.$t('common.headers.name.title'),
          minWidth: 120,
          truncate: true,
          fallback: '-'
        },
        {
          field: 'product_group',
          label: this.$t('pages.reservations.services.category.label'),
          minWidth: 120,
          truncate: true,
          fallback: '-',
          formatter: (row, column) => {
            let serviceCategoryItem
            serviceCategoryItem = (this.resources.serviceCategories || []).find(
              (item) => item.product_group_id === row.product_group
            )
            return serviceCategoryItem ? serviceCategoryItem.name : '-'
          }
        },
        {
          field: 'duration',
          label:
            this.$t('pages.reservations.services.duration.label') +
            ' (' +
            this.$t('pages.reservations.services.duration.minutes.label') +
            ')',
          minWidth: 120,
          truncate: true,
          fallback: '-'
        },
        {
          field: 'description',
          label: this.$t('pages.reservations.services.description.label'),
          minWidth: 180,
          truncate: true,
          fallback: '-'
        },
        {
          field: 'linked_product',
          customRowComponent: ServicesTableRowLinkedProduct,
          label: this.$t('pages.reservations.services.linked_product.label'),
          minWidth: 250,
          truncate: true,
          options: this.resources.products
        }
        // {
        //   field: '_branch_label',
        //   label: this.$t('common.resource.branch.singular'),
        //   minWidth: 250,
        //   truncate: true
        // }
      ]
    },
    filtersList() {
      return [
        {
          name: 'linked_product_id',
          type: 'select',
          placeholder: this.$t(
            'pages.reservations.services.linked_product.placeholder'
          ),
          label: this.$t('pages.reservations.services.linked_product.label'),
          options: this.linkedProductOptions,
          filterable: true,
          formatValue: (value) => {
            if (!this.resources.products) return value
            return (
              (this.resources.products.find((item) => item.id === value) || {})
                .name || ' - '
            )
          }
        },
        {
          name: 'product_group',
          type: 'multiselect',
          placeholder: this.$t(
            'pages.reservations.services.category.placeholder'
          ),
          label: this.$t('pages.reservations.services.category.label'),
          options: this.serviceCategoriesOptions,
          filterable: true
        }
        // {
        //   name: 'locations',
        //   type: 'multiselect',
        //   collapseTags: true,
        //   label: this.$t('common.resource.branch.plural'),
        //   options: this.branchOptions,
        //   filterable: true
        // }
      ]
    },
    branchOptions() {
      return (
        this.resources.branches?.map((branch) => ({
          label: branch.name,
          value: branch.id
        })) ?? []
      )
    },
    serviceCategoriesOptions() {
      return (this.resources?.serviceCategories || [])
        .map((category) => {
          return {
            label: category.name,
            value: category.product_group_id
          }
        })
        .sort((a, b) => (a.label >= b.label ? 1 : -1))
    },
    linkedProductOptions() {
      return (this.resources?.products || [])
        .map((product) => {
          return {
            label: product.custom_id + ' - ' + product.name,
            value: product.id
          }
        })
        .sort((a, b) => (a.label >= b.label ? 1 : -1))
    }
  },
  async beforeMount() {
    try {
      await this.fetchResources()
    } catch (err) {
      this.$logException(err, { trackError: false })
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    getBranchLabel(service, branches) {
      if (service.locations === null) {
        return this.$t('pages.reservations.services.available_in.everywhere')
      }

      if (service.locations.length === 0) {
        return this.$t('pages.reservations.services.available_in.nowhere')
      }

      const branchNames = service.locations
        .map((branchId) => branches.find((branch) => branch.id === branchId))
        .filter((branch) => !!branch)
        .map((branch) => branch.name)
        .join(', ')

      return branchNames || '-'
    },
    async transformFetchedData(services) {
      await waitForData(() => !!this.resources.branches)

      services.forEach((service) => {
        service._branch_label = this.getBranchLabel(
          service,
          this.resources.branches
        )
      })

      return services
    },
    async refresh() {
      // The importer creates service categories, if the customer enter a name
      // which did not previously exist
      await this.fetchResources()
      this.$refs.table.refresh()
    },
    async fetch() {
      try {
        this.loading = true
        const { products } = await this.$resourceFetch({
          resource: 'products',
          query: { is_reservations_service: true, deleted: false }
        })
        this.isEmpty = Array.isArray(products) && !products.length
      } catch (err) {
        this.handleLoadingError(err)
      } finally {
        this.loading = false
      }
    },
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('nav.main.items.reservations.services')
        })
      })
    },
    async fetchResources() {
      const {
        serviceCategories,
        products,
        branchesV1: branches
      } = await this.$resourceFetch(
        'branchesV1',
        {
          prop: 'serviceCategories',
          resource: 'product_groups',
          query: { is_service_category: true, deleted: false }
        },
        {
          resource: 'products',
          query: {
            deleted: false,
            exclude_system_products: true,
            type: [
              'product',
              'composed_product',
              'voucher',
              'linked',
              'linked_product',
              'variant',
              'variant_product'
            ]
          }
        }
      )

      this.resources = { serviceCategories, products, branches }
    }
  }
}
</script>
