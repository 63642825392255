/**
 * Creates a hashmap from an array
 * [{ id: 'udc', name: 'George' }] => { 'udc': { name: 'George' }}
 * @param {[]} array
 * @param {string | (item) => string} propertyOrGetter
 * @returns
 */
export function indexBy(array, propertyOrGetter) {
  const getKey =
    typeof propertyOrGetter === 'string'
      ? (item) => item[propertyOrGetter]
      : propertyOrGetter

  return array.reduce((acc, item) => {
    acc[getKey(item)] = item
    return acc
  }, {})
}
