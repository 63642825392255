<template>
  <div
    class="w-full h-full flex flex-col bg-th-navigation"
    data-testid="services-empty"
  >
    <div class="flex justify-between items-center py-6 pr-6 pl-8">
      <div
        class="h-full flex flex-col flex-end font-bold leading-7 text-lg mr-10"
        :class="{ italic: !isWhiteLabel }"
      >
        <div>
          {{ $t('pages.reservations.services.empty.text.title') }}
        </div>
        <div>
          {{ $t('pages.reservations.services.empty.text.sub') }}
        </div>
      </div>

      <div class="flex-shrink-0">
        <el-dropdown
          split-button
          type="primary"
          icon="Plus"
          @click="computedButtons[0].clickHandler"
        >
          <span>{{ computedButtons[0].label }}</span>

          <template #dropdown>
            <template-file-button :href="templateHref" />
            <services-importer
              :resources="resources"
              @refresh="$emit('refresh')"
            />
          </template>
        </el-dropdown>
      </div>
    </div>
    <div class="h-full flex min-h-0 min-w-0 items-center justify-center">
      <img
        :src="productsListEmpty"
        alt="products-list-empty"
        style="max-height: 100%; width: auto"
      />
    </div>
    <div class="th-shadow-top h-20" />
  </div>
</template>

<script>
import productsListEmpty from '@/assets/illustrations/reservations-empty.svg'
import { isUnifiedCommerce } from '@/constants'
import ServicesImporter from './components/services-importer.vue'
import TemplateFileButton from '@/components/importer/template-file-button.vue'

export default {
  components: {
    ServicesImporter,
    TemplateFileButton
  },
  props: {
    templateHref: {
      type: String,
      required: true
    }
  },
  emitс: ['refresh'],
  data() {
    return {
      productsListEmpty,
      resources: {},
      buttons: [
        {
          scopes: ['services:create'],
          svgicon: 'th-icon-plus',
          label: this.$t('common.forms.labels.new'),
          clickHandler: this.handleNew
        }
      ]
    }
  },
  computed: {
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    },
    isWhiteLabel() {
      return isUnifiedCommerce()
    }
  },
  async created() {
    try {
      await this.fetchResources()
    } catch (err) {
      this.$logException(err, { trackError: false })
    }
  },
  methods: {
    async fetchResources() {
      const { serviceCategories, products } = await this.$resourceFetch(
        {
          prop: 'serviceCategories',
          resource: 'product_groups',
          query: { is_service_category: true, deleted: false }
        },
        {
          resource: 'products',
          query: {
            deleted: false,
            exclude_system_products: true,
            type: [
              'product',
              'composed_product',
              'voucher',
              'linked',
              'linked_product',
              'variant',
              'variant_product'
            ]
          }
        }
      )

      this.resources = { serviceCategories, products }
    },
    handleNew() {
      this.$router.push({ name: 'services-new' })
    }
  }
}
</script>

<style scoped>
.th-shadow-top {
  box-shadow: 0px -4px 4px rgba(167, 171, 177, 0.15);
}
</style>
