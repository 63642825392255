<template>
  <el-dropdown-item @click.stop="handleImport">
    <svgicon
      :src="require('@/assets/icons/th-icon-upload.svg')"
      :style="{ height: '20px', width: '20px' }"
      class="mr-2 fill-current"
    />
    <span>{{ $t('common.interactions.buttons.import') }}</span>
  </el-dropdown-item>
</template>

<script>
import { useStore } from 'vuex'
import { computed, onMounted, onUnmounted, defineComponent, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import th from '@tillhub/javascript-sdk'
import FlatfileImporter from '@flatfile/adapter'
import { i18nOverrides } from '@/utils/importer'
import { indexBy } from '@utils/arrays'
import pRetry from 'p-retry'
import pLimit from 'p-limit'

const FLATFILE_LICENSE_KEY = process.env.VUE_APP_FLATFILE_LICENSE_KEY

export default defineComponent({
  name: 'ServicesImporter',
  props: {
    resources: {
      type: Object,
      required: true
    }
  },
  emits: ['refresh'],
  setup(props, { emit }) {
    const store = useStore()
    const clientAccountConfiguration = computed(
      () => store.getters['Config/getClientAccountConfiguration']
    )
    const logException = inject('logException')
    const userId = computed(() => store.state.Auth.user || '-')
    const orgName = computed(() => store.state.Auth.orgName || '-')
    const { t, tm } = useI18n()
    const fields = computed(() => [
      {
        key: 'name',
        label: t('common.forms.labels.name'),
        validators: [{ validate: 'required' }]
      },

      {
        key: 'duration',
        label: t('pages.reservations.services.duration.label'),
        validators: [{ validate: 'required' }]
      },
      {
        key: 'description',
        label: t('pages.reservations.services.description.label')
      },
      {
        key: 'linked_product_number',
        label: t('pages.reservations.services.importer.fields.product_number'),
        validators: [{ validate: 'required' }]
      },
      {
        key: 'service_category',
        label: t('pages.reservations.services.category.label')
      },
      {
        key: 'service_category_description',
        label: t(
          'pages.reservations.services.importer.fields.service_category_description'
        )
      }
    ])

    const importer = computed(() => {
      return new FlatfileImporter(FLATFILE_LICENSE_KEY, {
        fields: fields.value,
        type: t('pages.services.title'),
        allowInvalidSubmit: false,
        managed: true,
        disableManualInput: true,
        devMode: process.env.NODE_ENV !== 'production',
        styleOverrides: {
          primaryButtonColor: '#279ff6',
          invertedButtonColor: '#7bbaf3'
        },
        i18nOverrides: {
          de: {
            otherLocales: ['de-DE'],
            overrides: i18nOverrides({
              header: tm('pages.services.importer.flatfile.header'),
              header2: tm('pages.services.importer.flatfile.header2')
            })
          },
          en: {
            otherLocales: ['en-US', 'en-GB'],
            overrides: i18nOverrides({
              header: tm('pages.services.importer.flatfile.header'),
              header2: tm('pages.services.importer.flatfile.header2')
            })
          }
        }
      })
    })

    onUnmounted(() => {
      importer.value.destroy()
    })

    const productsByNumber = computed(() => {
      return indexBy(props.resources.products ?? [], 'custom_id')
    })

    const serviceCategoriesByName = computed(() => {
      return indexBy(props.resources.serviceCategories ?? [], 'name')
    })

    function getProductByProductNumber(productNumber) {
      return productsByNumber.value[productNumber] ?? null
    }

    function getServiceCategory(name) {
      return serviceCategoriesByName.value[name] ?? null
    }

    function validateRecords(record) {
      const out = {}
      const duration = +record.duration
      if (Number.isNaN(duration)) {
        out.duration = {
          info: [
            {
              message: t('pages.reservations.services.duration.required'),
              level: 'error'
            }
          ]
        }
      } else if (duration > 1440) {
        out.duration = {
          info: [
            {
              message: t('pages.reservations.services.duration.more_than'),
              level: 'error'
            }
          ]
        }
      }
      if (record.description?.length > 4096) {
        out.description = {
          info: [
            {
              message: t('common.forms.rules.max_length', { length: 4096 }),
              level: 'error'
            }
          ]
        }
      }
      if (record.name.length < 3 || record.name.length > 128) {
        out.name = {
          info: [
            {
              message: t('common.forms.rules.min_max_length', {
                min: 3,
                max: 128
              }),
              level: 'error'
            }
          ]
        }
      }

      const linkedProduct = getProductByProductNumber(
        record.linked_product_number
      )

      if (!linkedProduct) {
        out.linked_product_number = {
          info: [
            {
              message: t('pages.reservations.services.linked_product.required'),
              level: 'error'
            }
          ]
        }
      }

      return out
    }

    onMounted(() => {
      importer.value.setCustomer({ userId: userId.value, name: orgName.value })
      importer.value.registerRecordHook(validateRecords)
    })

    async function handleImport() {
      try {
        const results = await importer.value.requestDataFromUser()
        importer.value.displayLoader()
        await createServices(results.data)
        importer.value.displaySuccess(
          t('pages.services.importer.messages.success')
        )
        emit('refresh')
      } catch (err) {
        // Flatfile throws undefined error when user closes the importer by clicking X button.
        if (err) {
          logException(err, { trackError: false })
          importer.value.displayError(
            t('pages.services.importer.messages.error')
          )
        }
      }
    }

    /**
     * Finds the names of service categories in the array that currently
     * do not exist and creates them.
     * @param {[]} serviceRecords
     */
    async function createMissingServiceCategories(serviceRecords) {
      const categoriesToCreate = serviceRecords
        .filter((service) => {
          const categoryName = service.service_category

          return !!categoryName && getServiceCategory(navigator) === null
        })
        .map((service) => {
          return {
            name: service.service_category,
            description: service.service_category_description || null
          }
        })

      const uniqueCategoriesToCreate = Object.values(
        indexBy(categoriesToCreate, 'name')
      )

      const createdCategories = []
      const inst = th.serviceCategory()
      const limit = pLimit(10)

      const promises = uniqueCategoriesToCreate
        .map((category) => async () => {
          const result = await inst.create(category)

          createdCategories.push(result.data)
        })
        .map((fn) => async () => {
          try {
            await pRetry(fn, {
              retries: 3,
              minTimeout: 100,
              maxTimeout: 1000,
              randomize: true
            })
          } catch (err) {
            logException(err)
          }
        })
        .map((fn) => limit(fn))

      await Promise.all(promises)

      return createdCategories
    }

    async function createServices(services) {
      const createdCategories = await createMissingServiceCategories(services)

      // A hashmap of categories, indexed by name. It includes the old and the new ones
      // that were just created
      const categoriesByName = {
        ...serviceCategoriesByName.value,
        ...indexBy(createdCategories, 'name')
      }

      const servicesToCreate = services.map((service) => {
        const linkedProduct =
          productsByNumber.value[service.linked_product_number]
        const category = categoriesByName[service.service_category]

        return {
          name: service.name,
          description: service.description,
          duration: +service.duration,
          product_group: category?.product_group_id ?? null,
          linked_product: linkedProduct.id
        }
      })

      const inst = th.services()
      const limit = pLimit(10)
      const promises = servicesToCreate
        .map((service) => () => inst.create(service))
        .map((fn) => async () => {
          try {
            await pRetry(fn, {
              retries: 3,
              minTimeout: 100,
              maxTimeout: 1000,
              randomize: true
            })
          } catch (err) {
            logException(err)
          }
        })
        .map((fn) => limit(fn))
      await Promise.all(promises)
    }
    return {
      userId,
      orgName,
      clientAccountConfiguration,
      handleImport,
      createServices
    }
  }
})
</script>
