<template>
  <div class="w-32" @click.prevent.stop>
    <el-button class="border-th-primary-blue" @click="openLinkedProduct">
      {{ currentLinkedProduct }}
    </el-button>
  </div>
</template>

<script>
export default {
  props: {
    scope: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      linkedProduct: this.scope.row.linked_product_id
    }
  },
  computed: {
    currentLinkedProduct() {
      let linkedProductItem
      linkedProductItem = (this.options || []).find(
        (item) => item.id === this.linkedProduct
      )
      return (
        linkedProductItem?.custom_id + ' - ' + linkedProductItem?.name || '-'
      )
    }
  },
  methods: {
    openLinkedProduct() {
      this.$router.push(`/products/manager/${this.linkedProduct}`)
    }
  }
}
</script>
